import Image from 'next/image'
import type { AppProps } from 'next/app'
import { Layout } from 'core/layout'
import { Toaster } from 'react-hot-toast'
import Head from 'next/head'
import styles from 'styles/toast.module.scss'
import 'styles/globals.scss'
import { MoralisProvider } from 'react-moralis'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import WalletConnect from '@walletconnect/client'

const moralisServer = process.env.NEXT_PUBLIC_MORALIS_SERVER ?? ''
const moralisAppId = process.env.NEXT_PUBLIC_MORALIS_APP_ID ?? ''

function MyApp({ Component, pageProps }: AppProps) {
  const [connector, setConnector] = useState<WalletConnect | null>(null)
  const [account, setAccount] = useState<string | null>(null)
  const router = useRouter()

  useEffect(() => {
    if (router.pathname !== '/privacy-policy') {
      const stored = localStorage.getItem('walletconnect')
      if (!stored) {
        router.push('/')
      } else {
        const data = JSON.parse(stored)
        setAccount(data.accounts[0])
        router.push('/connected')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MoralisProvider serverUrl={moralisServer} appId={moralisAppId}>
      <Layout>
        <Head>
          <title>Sidekick</title>
        </Head>
        <Component
          {...pageProps}
          connector={connector}
          setConnector={setConnector}
          account={account}
          setAccount={setAccount}
        />
      </Layout>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            icon: (
              <Image src="/success.svg" width={24} height={24} alt="success" />
            ),
            className: styles.success,
          },
          error: {
            icon: <Image src="/error.svg" width={24} height={24} alt="error" />,
            className: styles.error,
          },
        }}
      />
    </MoralisProvider>
  )
}

export default MyApp
