import Image from 'next/image'
import cn from 'classnames'
import type { WithChildren } from 'types/with-children'
import styles from './layout.module.scss'

export type LayoutProps = WithChildren<{
  className?: string
}>

export const Layout = ({ className, children }: LayoutProps) => {
  return (
    <div className={styles.layout}>
      <div className={styles['layout__brand']}>
        <Image src="/brand.svg" width={304} height={90} alt="sidekick by NCS" />
        <Image
          src="/mewp_whoop.gif"
          width={98}
          height={360}
          alt="mewp figure"
          unoptimized
        />
        <Image src="/mewp.svg" width={32} height={30} alt="mewp figure" />
      </div>
      <div className={cn(styles['layout__content'], className)}>{children}</div>
    </div>
  )
}
